import React from 'react';
import styles from './styles.module.scss';
import Button from '@components/Button';

const ContactCTA = () => {
    return (
        <div className={styles.contactSectionContainer}>
            <div className={styles.contactSection}>
                <h3>Start your journey</h3>
                <p>It might be easier than you think. Get in touch today.</p>
                <div className={styles.contactSectionButtons}>
                    <a rel="noreferrer noopener" href="mailto:hello@liveyou.co.uk">
                        <Button text="Contact us" link={"/contact"}/>
                    </a>
                    <Button
                        secondary
                        text="Open chat"
                        onClick={() => window?.FB?.CustomerChat?.show(true)}
                    />
                    {/*<Button*/}
                    {/*    secondary*/}
                    {/*    text="Open Facebook"*/}
                    {/*    onClick={() => {*/}
                    {/*        window.location.href = 'https://www.facebook.com/LiveYouUK';*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
    );
};

export default ContactCTA;
