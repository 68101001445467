import React, { Fragment, useState } from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import TapTextCard from '@components/TapTextCard';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { graphql, Link } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import Accordion from '@components/Accordion';
import ContactCTA from '@components/ContactCTA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalTimelineElement, VerticalTimeline } from 'react-vertical-timeline-component';

const Faq = ({
    data: {
        page: {
            frontmatter: { title, description, questions },
        },
    },
}) => {
    const [openItemIndex, setOpenItemIndex] = useState(null);

    return (
        <Layout>
            <SEO title={`FAQ`} />
            <div className={styles.faqWrapper}>
                <Header
                    title={title}
                    description={description}
                    graphicName="/images/our-values-upholding-your-rights.svg"
                    graphic = {true}
                />
                <div className={styles.faqContent}>
                    <Grid>
                        <Row between="xs">
                            <Col xs={12}>
                                <div className={styles.timeline}>
                                    <VerticalTimeline layout="1-column-left">
                                        {questions?.map(({ title, description }, index) => (
                                            <VerticalTimelineElement
                                                iconStyle={{
                                                    background: '#FF7F50',
                                                    color: '#fff',
                                                }}
                                                icon={
                                                    <FontAwesomeIcon
                                                        icon={['fas', 'question']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                }
                                                key={title}
                                            >
                                                <div className={styles.faqListContainer}>
                                                    <Accordion
                                                        open={openItemIndex === index}
                                                        onToggle={open => setOpenItemIndex(open ? index : null)}
                                                        title={title}
                                                        html={description}
                                                    />
                                                </div>
                                            </VerticalTimelineElement>
                                        ))}
                                    </VerticalTimeline>
                                </div>

                            </Col>
                        </Row>
                    </Grid>

                </div>
            </div>

            <Divider />
            <ContactCTA />
        </Layout>
    );
};

export default Faq;

export const query = graphql`
    query faqPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: faq_title
                description: faq_description
                questions: faq_questions {
                    title: faq_questions_title
                    description: faq_questions_description
                }
            }
        }
    }
`;
